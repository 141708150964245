import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import classnames from 'classnames';

import { Routes } from 'types/routes';

import { getLocaleConfig } from 'utils/locale';

import LocaleContext from 'components/context/Locale';
import Button, { ButtonIconPositions, ButtonIconTypes, ButtonStyles } from 'components/layout/Button';
import LayoutContainer, { Sizes } from 'components/layout/LayoutContainer';
import Logo from 'components/layout/Logo';
import InfoLineNavSection from 'components/modules/public/layout/Infoline/component';

import StyledComponent from './styles';
import { Props } from './types';

const DropDownMenu = dynamic(() => import('components/modules/public/cart/DropDownMenu'));

const PublicLayoutTopBar: FunctionComponent<Props> = ({
    className,
    isScrolledTop,
    state,
    addedToCart,
    setAddedToCart,
    navigationElementVisibility,
    isLogoRedirect,
}) => {
    const [isProfileHovered, setIsProfileHovered] = useState<boolean>(false);
    const localeContext = useContext(LocaleContext);
    const currentItemsQuantityInCart = useRef(undefined);

    const translations = localeContext?.translations?.navigation;

    const lightLogo = getLocaleConfig()[localeContext.locale]?.defaultLogoLightUrl;
    const darkLogo = getLocaleConfig()[localeContext.locale]?.defaultLogoDarkUrl;

    const activeCountCart = state?.cart?.items?.filter((cartItem) => cartItem?.isAccepted);

    useEffect(() => {
        if (!activeCountCart?.length) return;
        const prevItemsQuantityInCart = currentItemsQuantityInCart.current;
        currentItemsQuantityInCart.current = activeCountCart?.length;

        if (currentItemsQuantityInCart.current > prevItemsQuantityInCart ||
            currentItemsQuantityInCart.current < prevItemsQuantityInCart) {
            setAddedToCart(true);
        }

        const timeoutAddToCart = setTimeout(() => setAddedToCart(false), 300);

        return () => {
            clearTimeout(timeoutAddToCart);
        };
    }, [activeCountCart?.length]);

    const getProfileImage = () => {
        if (state?.authToken) {
            return isProfileHovered ? '/images/profile-dark.svg' : '/images/profile-solid.svg';
        }
        if (!state?.authToken && isScrolledTop) {
            return isProfileHovered ? '/images/profile-solid.svg' : '/images/profile-light.svg';
        }
        return isProfileHovered ? '/images/profile-solid.svg' : '/images/profile-dark.svg';
    };

    return (
        <StyledComponent
            className={classnames(
                'top',
                className
            )}
        >
            <LayoutContainer size={Sizes.XXMedium}>
                <div className="col col-left">
                    {navigationElementVisibility?.logo && (
                        <>
                            {isLogoRedirect ? (
                                <Link
                                    href="/"
                                    prefetch={false}
                                >
                                    <a className="logo-link">
                                        <Logo logo={isScrolledTop ? lightLogo : darkLogo} />
                                    </a>
                                </Link>
                            ) : (
                                <Logo logo={isScrolledTop ? lightLogo : darkLogo} />
                            )}
                        </>
                    )}
                </div>
                <div className="col col-right">
                    <a
                        href="/strefa-kobiet/konsultacja-online/"
                        className="offer-button"
                        rel="noopener"
                    >
                        <img
                            src="/theme/images/globe.svg"
                            aria-hidden="true"
                            alt="globe"
                            className="icon"
                            width={20}
                            height={20}
                        />
                        <p className="offer-button-text">
                            Konsultacja online z kosmetologiem
                        </p>
                    </a>
                    {navigationElementVisibility?.infoLine && (
                        <InfoLineNavSection
                            translations={translations}
                            isScrolledTop={isScrolledTop}
                        />
                    )}
                    {navigationElementVisibility?.profile && (
                        <div className="profile">
                            <Link
                                href={Routes.Panel}
                                prefetch={false}
                            >
                                <div className="profile-button">
                                    {state?.authToken && (<div className="is-logged-in" />)}
                                    <Button
                                        className={classnames(
                                            {
                                                'profile-link': true,
                                                'profile-link-logged-in': !!state?.authToken,
                                            }
                                        )}
                                        style={ButtonStyles.None}
                                        buttonProps={{
                                            onMouseEnter: () => setIsProfileHovered(true),
                                            onMouseLeave: () => setIsProfileHovered(false),
                                        }}
                                        icon={{
                                            value: getProfileImage(),
                                            type: ButtonIconTypes.Image,
                                            position: ButtonIconPositions.After,
                                        }}
                                    />
                                </div>
                            </Link>
                        </div>
                    )}
                    {navigationElementVisibility?.cart && (
                        <div className="cart">
                            <Link
                                href={Routes.PublicCart}
                                prefetch={false}
                            >
                                <div className="cart-button">
                                    {activeCountCart?.length > 0 && (
                                        <div className={classnames(
                                            'cart-counter',
                                            addedToCart && 'added'
                                        )}
                                        >
                                            {activeCountCart?.length}
                                        </div>
                                    )}
                                    <Button
                                        className="cart-link"
                                        style={ButtonStyles.None}
                                        icon={{
                                            value: isScrolledTop ? '/images/home/cart-solid-light.svg' : '/images/home/cart-solid.svg',
                                            type: ButtonIconTypes.Image,
                                            position: ButtonIconPositions.After,
                                        }}
                                    />
                                </div>
                            </Link>
                            <DropDownMenu />
                        </div>
                    )}
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicLayoutTopBar;
